export function getAPI() {
    return (window as any)["API_URL"];
}

function secureFetch(url: string, init: RequestInit) {
    init["credentials"] = "include"
    return new Promise<Response>((resolve, reject) => {
        fetch(url, init).then(response => {
            if (response.status === 401) {
                window.location.href = `${getAPI()}/api/login?redirect=${encodeURIComponent(window.location.href)}`;
                return
            }
            if (response.ok) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(error => {
            alert(error.toString());
            reject(error);
        });
    });
}

export const getDashboard = () => secureFetch(`${getAPI()}/api/dashboard`, {}).then((response) => response.json())

export const transfer = (newContract: string, test: boolean) =>
    secureFetch(`${getAPI()}/api/transfer`, {
        method: "POST",
        headers: {'content-type': 'application/x-www-form-urlencoded'},
        body: new URLSearchParams({
            "contract": newContract,
            "test": test.toString()
        })
    }).then((response) => response.text()).catch(response => {
        if (response.status == 403) {
            return Promise.reject("Not allowed")
        } else {
            return response.text();
        }
    })

export const withdraw = (token: string) =>
    secureFetch(`${getAPI()}/api/withdraw`, {
        method: "POST",
        headers: {'content-type': 'application/x-www-form-urlencoded'},
        body: new URLSearchParams({
            "token": token
        })
    }).then((response) => response.text())


export const updateContract = (newContract: string) =>
    fetch(`${getAPI()}/api/contract`, {
        method: "POST",
        headers: {'content-type': 'application/x-www-form-urlencoded'},
        credentials: "include",
        body: new URLSearchParams({
            "contract": newContract,
        })
    }).catch((err) => {
        alert(err.message);
    })

export const buy = (pair: string, amountIn: string, test: boolean) =>
    fetch(`${getAPI()}/api/buy`, {
        method: "POST",
        headers: {'content-type': 'application/x-www-form-urlencoded'},
        credentials: "include",
        body: new URLSearchParams({
            "pair": pair,
            "amountIn": amountIn,
            "test": test.toString()
        })
    }).catch((err) => {
        alert(err.message);
    })

export const sell = (pair: string, amountIn: string, test: boolean) =>
    fetch(`${getAPI()}/api/sell`, {
        method: "POST",
        headers: {'content-type': 'application/x-www-form-urlencoded'},
        credentials: "include",
        body: new URLSearchParams({
            "pair": pair,
            "amountIn": amountIn,
            "test": test.toString()
        })
    }).catch((err) => {
        alert(err.message);
    })

export const addPair = (newPair: string) =>
    fetch(`${getAPI()}/api/pair/${newPair}`, {method: "PUT", credentials: "include"}).catch((err) => {
        alert(err.message);
    })


export const deletePair = (pair: string) =>
    fetch(`${getAPI()}/api/pair/${pair}`, {method: "DELETE", credentials: "include"}).catch((err) => {
        alert(err.message);
    })

