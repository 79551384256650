import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {addPair, buy, deletePair, getAPI, getDashboard, sell, transfer, updateContract, withdraw} from "./rest";

type Pair = {
    name: string,
    address: string,
    mainTokenName: string
    secToken: string
    secTokenSymbol: string
    secTokenName: string
    balance: bigint
    balanceFormatted: string
    tokensPriceFormatted: string
    priceFormatted: string
}

type RatingPair = {
    name: string,
    address: string
    mainReserveUSD: string
    token: string
}

type Data = {
    block: number,
    contract: string,
    mainBalances: { [key: string]: string }
    pairs: Pair[]
    bestPairs: RatingPair[]
}

const StyledHeader = styled.div`
  h1 {
    font-size: 20px;
  }

  & > * {
    padding-bottom: 10px;
  }

  span, button {
    margin-right: 10px;
  }
`

const StyledPair = styled.div`
  display: inline-block;
  position: relative;
  border: 1px solid black;
  padding: 10px;
  margin: 3px;
  line-height: 1.5;

  h2 {
    padding: 0 40px 0 0;
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  h2 img {
    padding-right: 10px;
  }

  h3 {
    color: #555;
    padding: 0 0 10px;
    font-size: 14px;
    font-weight: normal;
  }

  div.row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  div.grid {
    display: grid;
    grid-gap: 5px;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  input {
    text-align: right;
  }

  button.delete {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`

const dexLink = (address: string, name: string) =>
    <a target="_blank" rel="noreferrer" href={`https://www.dextools.io/app/en/bnb/pair-explorer/${address}`}>{name}</a>

const dexImage = (address: string) =>
    <img style={{width: "32px", height: "32px"}} src={`${getAPI()}/image/${address}`}/>

const addressLink = (address: string, name: string) =>
    <a target="_blank" rel="noreferrer" href={`https://bscscan.com/address/${address}#tokentxns`}>{name}</a>

const TokensTransfersLink = (props: { children: React.JSX.Element, contract: string, token: string }) =>
    <a target="_blank" rel="noreferrer"
       href={`https://bscscan.com/token/${props.token}?a=${props.contract}`}>{props.children}</a>

interface BestPairsProps {
    pairs: RatingPair[],
}

export const BestPairs = (props: BestPairsProps) => (
    <table>
        {props.pairs.map((pair, index) => (
            <tr>
                <td>{index + 1}</td>
                <td>{dexImage(pair.token)}</td>
                <td>{dexLink(pair.address, pair.name)}</td>
                <td>{pair.mainReserveUSD}</td>
            </tr>
        ))}
    </table>
)

export const Dashboard = () => {
    const [data, setData] = useState<Data>();

    const update = () => {
        getDashboard().then(data => setData(data))
    }

    useEffect(() => {
        const interval = setInterval(() => {
            update()
        }, 10000);
        return () => clearInterval(interval);
    }, []);


    if (data == null) {
        return <h1>Loading...</h1>
    }

    const handleTransfer = () => {
        const newContract = prompt("Enter new contract");
        if (newContract) {
            transfer(newContract, true).then(response => {
                if (window.confirm(response)) {
                    transfer(newContract, false).then(response => {
                        update()
                        alert(response)
                    }).catch(error => {
                        alert(error)
                    })
                }
            }).catch(error => {
                alert(error)
            })
        }
    }

    const handleUpdateContract = () => {
        const newContract = prompt("Enter new contract");
        if (newContract) {
            updateContract(newContract).then(update);
        }
    }

    const handleWithdraw = (token: string, symbol: string) => {
        const confirm = window.confirm(`Withdraw ${symbol}?`);
        if (confirm) {
            withdraw(token).then(tx => alert(tx)).then(update);
        }
    }

    const handleAddPair = () => {
        const newPair = prompt("Enter new pair");
        if (newPair) {
            addPair(newPair).then(update);
        }
    }

    const handleBuy = (pair: string, amountIn: string) => {
        buy(pair, amountIn, false).then((response) => {
            if (response instanceof Response) {
                return response.text()
            }
        }).then(response => {
            if (response) {
                update();
                alert(response);
            }
        })
    }

    const handleSell = (pair: string, amountIn: string) => {
        sell(pair, amountIn, false).then((response) => {
            if (response instanceof Response) {
                return response.text()
            }
        }).then(response => {
            if (response) {
                update();
                alert(response);
            }
        })
    }


    const handleTestBuy = (pair: string, tokenName: string) => {
        const amountIn = prompt(`Enter ${tokenName} amount in`);
        if (amountIn) {
            buy(pair, amountIn, true).then((response) => {
                if (response instanceof Response) {
                    return response.text()
                }
            }).then(text => {
                if (text && window.confirm(text)) {
                    handleBuy(pair, amountIn)
                }
            });
        }
    }

    const handleTestSell = (pair: string, tokenName: string, amountIn: string | null) => {
        if (!amountIn) {
            amountIn = prompt(`Enter ${tokenName} amount in or % to sell.`);
        }
        if (amountIn) {
            sell(pair, amountIn, true).then((response) => {
                if (response instanceof Response) {
                    return response.text()
                }
            }).then(text => {
                if (text && window.confirm(text)) {
                    handleSell(pair, amountIn!)
                }
            });
        }
    }

    const handleDeletePair = (pair: string) => {
        if (window.confirm("Delete pair?")) {
            deletePair(pair).then(() => update());
        }
    }

    return <div>
        <StyledHeader>
            <h1>{addressLink(data.contract, data.contract)}</h1>
            <div>
                <span>Block: {data.block}</span>
                {Object.values(data.mainBalances).map((token) => (
                    <span>{token}</span>
                ))}
            </div>
            <div>
                <button onClick={handleUpdateContract}>Update Contact</button>
                <button onClick={handleTransfer}>Transfer</button>
                <button onClick={handleAddPair}>Add Pair</button>
            </div>
        </StyledHeader>
        {data.pairs.map((pair) => (
            <StyledPair>
                <button className="delete" onClick={() => handleDeletePair(pair.address)}>X</button>
                <h2>
                    <TokensTransfersLink contract={data.contract} token={pair.secToken}>
                        {dexImage(pair.secToken)}
                    </TokensTransfersLink>
                    {dexLink(pair.address, pair.name)}
                </h2>
                <h3>{pair.secTokenName}</h3>
                <div className="row">
                    <span>Balance:</span>
                    <span>{pair.balanceFormatted}</span>
                </div>
                <div className="row">
                    <span>Tokens price:</span>
                    <span>{pair.tokensPriceFormatted}</span>
                </div>
                <div className="row">
                    <span>Price:</span>
                    <span>{pair.priceFormatted}</span>
                </div>
                <div className="grid">
                    <button onClick={() => handleTestBuy(pair.address, pair.mainTokenName)}>Buy</button>
                    <button onClick={() => handleTestSell(pair.address, pair.secTokenSymbol, null)}>Sell</button>
                    <button onClick={() => handleWithdraw(pair.secToken, pair.secTokenSymbol)}>WD</button>
                </div>
                <div className="grid">
                    <button onClick={() => handleTestSell(pair.address, pair.secTokenSymbol, "10%")}>10%</button>
                    <button onClick={() => handleTestSell(pair.address, pair.secTokenSymbol, "30%")}>30%</button>
                    <button onClick={() => handleTestSell(pair.address, pair.secTokenSymbol, "100%")}>100%</button>
                </div>
            </StyledPair>)
        )}
        <hr/>
        <BestPairs pairs={data.bestPairs}/>
    </div>
}